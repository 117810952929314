<script>
import { ref, onMounted, watch, onUpdated,computed, nextTick } from 'vue'
import { Dialog } from 'vant'
import router from '@/routes'
import { Toast } from 'vant'
import { regFenToYuan, regYuanToFen, encodeValue, decodeValue } from '@/utils'
import { http } from '@/http'

export default {
  setup() {
    let bankInfo = ref([])
    let balance = ref('')
    let riskMin = ref('')
    let riskMax = ref('')
    let amount = ref('')
    let arrivalAmount = ref('')
    let serviceCharge = ref('')
    let taxCharge = ref('')
    let showDialog = ref(false)


    function withdrawalIndex() {
      http.post('/?cmd=com.xqxc.api.pigear.pay.BrokerWithdrawalService.withdrawalIndex').then(res => {
        if(res.result) {
          bankInfo.value = res.result.bankInfo
          balance.value = res.result.balance
          riskMin.value = res.result.riskMin
          riskMax.value = res.result.riskMax

        }
      })
    }

    function showAmountDialog() {
      if(regFenToYuan(balance.value) <= 1) {
        Toast('提现金额超出收益金额！')
        return false
      }
      http.post('/?cmd=com.xqxc.api.pigear.pay.BrokerWithdrawalService.withdrawalCalculate', {
        amount: parseInt((Number(regYuanToFen(amount.value))), 10) 
      }).then(res => {
        if(res.result) {
          showDialog.value = true
          arrivalAmount.value = res.result.arrivalAmount
          serviceCharge.value = res.result.serviceCharge
          taxCharge.value = res.result.taxCharge
        }
        else {}
      })
    }

    function confirmDialog() {
      http.post('/?cmd=com.xqxc.api.pigear.pay.BrokerWithdrawalService.confirmWithdrawal', {
        bindBankId: (bankInfo.value[0] && bankInfo.value[0].bindBankId) || undefined,
        amount: parseInt(regYuanToFen(Number(amount.value)), 10)
      }).then(res => {
        if(res.result) {
          router.push(`/income/draw-success?v=${encodeValue(res.result)}`)
        }
      })
    }

    watch(() => amount.value, (a, b) => {
      nextTick(() => {
        amount.value = amount.value.replace(/\D/g, '')
      })
      
    })
    let handleBalance = computed(() => `${regFenToYuan(balance.value).split('.')[0]}.${regFenToYuan(balance.value).split('.')[1].substring(0,2)}`)

    onMounted(withdrawalIndex)

    return {
      bankInfo,
      balance: handleBalance,
      riskMin,
      riskMax,
      regFenToYuan,
      showDialog,
      amount,
      showAmountDialog,
      serviceCharge,
      taxCharge,
      arrivalAmount,
      confirmDialog,
    }
  },
  components: {
    [Dialog.Component.name]: Dialog.Component,
  },


}
</script>
<template>
  <div class="page">
    <!-- <div class="b-hd">
      <div>
        <p>当前收益<span>1300.00</span></p>
      </div>
    </div> -->
    <div class="box">
      <p class="bank" @click="$router.push('/bind-bankcard')" v-if="bankInfo.length">{{bankInfo[0].bankName}}({{bankInfo[0].bankNo}})
        <span>人工审核，预计48小时内到账</span>
        <span style="    position: absolute;
    right: 0.3rem;
    top: 0.6rem;
    font-size: .4rem">&gt;</span>
      </p>  
    </div>
    <div class="box">
      <p style="font-size:.28rem; padding: .3rem">提现金额</p>
      <van-field
        label="￥"
        type="number"
        label-width="1.5em"
        v-model="amount"
        placeholder="请输入提现金额"
        style="font-size:.48rem"
      >
        <template #extra>
          <span v-show="balance >= 1" @click="amount = balance.replace(/^(\d+)\.(\d+)/g, '$1')" style="font-size:.28rem; color:#00ABEB">全部支取</span>
        </template>
      </van-field>
      <p style="margin:.3rem 0 1rem .3rem">可支取收益{{balance}}元</p>
      <div class="tips">
        <strong>温馨提示</strong>
        <p>1、单笔最低{{regFenToYuan(riskMin,3)}}元 </p>
        <p>2、单次最多提现金额不超过{{regFenToYuan(riskMax)}}元。</p>
        <p>3、提现金额只能为整数，暂不支持小数位金额的提现</p>
      </div>
    </div>
    <div style="margin: 30px .3rem;">
      <van-button block type="primary" @click="showAmountDialog">提现</van-button>
    </div>
    <!--   <div class="amountDialog" ref="amountDialogRef" v-show="showDialog">
      
    </div> -->
    <van-dialog show-cancel-button @confirm="confirmDialog" v-model:show="showDialog" title="支取到账金额">
      <div class="amountDialog">
        <h3>支取到账金额</h3>
        <p style="color:#ee0a24; font-size:.4rem; font-weight:bold">￥{{regFenToYuan(arrivalAmount)}}</p>
        <p><span>手续费</span><span>￥{{regFenToYuan(serviceCharge)}}</span></p>
        <p><span>个税扣减</span><span>￥{{regFenToYuan(taxCharge)}}</span></p>
      </div>
    </van-dialog>
  </div>

</template>

<style scoped>
  .b-hd { display: table; color: #fff; width: 100%; text-align: center;height:2.66rem; background-color:#00ABEB }
  .b-hd div { display:table-cell; vertical-align: middle}
  .b-hd p { font-size:.24rem }
  .b-hd span { display: block; margin-top: .3rem; font-size:.6rem }
  .box { margin-bottom: .1rem; background:#fff }
  .tips { padding: .3rem }
  .tips strong { display: block; margin-bottom: .2rem;font-size:.28rem }
  .tips p { margin-top: .1rem; line-height:1.3; font-size:.28rem; color:#666 }
  .bank { padding: .53rem .3rem; font-size:.32rem }
  .bank span { display: block; margin-top: .2rem; font-size:.28rem; color:#666 }
  .amountDialog { font-size: .3rem; padding: .5rem .5rem .3rem; text-align:center }
  .amountDialog p { display: table; width: 100%; margin:.3rem 0 }
  .amountDialog p span { display:table-cell; width:50%; padding-top:.2rem }
  .amountDialog p span:nth-of-type(1) { text-align:left }
  .amountDialog p span:nth-of-type(2) { text-align:right }
</style>